import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productCart: 0,
}

const cartNumber = createSlice({
    name: "cartNumber",
    initialState,
    reducers: {
        increaseProductNumber: (state) => {
            state.productCart += 1
        },
        resetProductNumber: (state) => {
            state.productCart = 0
        },
    }
})

export const { increaseProductNumber, resetProductNumber } = cartNumber.actions
export default cartNumber.reducer