import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdCloudUpload } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import AutocompleteCompo from "../../component/AutocompleteCompo";
import SelectField from "../../component/SelectField";

function STCoupons() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [reload, setReload] = useState(false);
  const [catagoryData, setCatagoryData] = useState([]);

  // coupon data
  const [couponName, setCouponName] = useState("");
  const [days, setDays] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [discount, setDiscount] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [isActive, setIsActive] = useState(undefined);

  // get coupon
  async function getCoupons() {
    console.log("hi");
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/coupon/admin/get/all`);
      console.log(res.data?.data);
      setCouponData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get catagory list
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/product/catagory/list`);
      const rawData = res.data?.data;
      let catagories = [];
      rawData?.map((item) => {
        catagories.push(item?.name);
      });
      catagories.push("All");
      setCatagoryData(catagories);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add coupon
  async function addCoupon() {
    if (
      couponName === "" ||
      discountType === "" ||
      discount === 0 ||
      days === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields is required");
      return null;
    }
    const data = {
      couponName: couponName,
      discount: discount,
      days: days,
      discountType: discountType,
      isPublic: isPublic,
      minimumAmount: minimumAmount,
    };
    try {
      setLoading(true);
      const res = axiosInstance.post("/api/v1/coupon/add", data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Coupon added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // update coupon
  async function updateCoupon(couponId) {
    const data = {
      couponName: couponName,
      discount: discount,
      days: days,
      discountType: discountType,
      isPublic: isPublic,
      minimumAmount: minimumAmount,
      couponId: couponId,
      isActive: isActive,
    };
    try {
      setLoading(true);
      const res = axiosInstance.put("/api/v1/coupon/edit", data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Coupon updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete coupon
  async function deleteCoupon(couponId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/coupon/delete/${couponId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Coupon deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // discount type array
  const discountTypeArray = [
    {
      title: "Percentage",
      value: "Percentage",
    },
    {
      title: "Rupees",
      value: "Rupees",
    },
  ];

  // is public array
  const isPublicArray = [
    {
      title: "Public",
      value: true,
    },
    {
      title: "Private",
      value: false,
    },
  ];

  // is active
  const isActiveArray = [
    {
      title: "Active",
      value: true,
    },
    {
      title: "Inactive",
      value: false,
    },
  ];

  // set value
  function setValue(data) {
    setCouponName(data?.coupon);
    setDiscount(Number(data?.discount));
    setDiscountType(data?.discountType);
    setMinimumAmount(data?.minimumAmount);
    setIsPublic(data?.isPublic);
    setIsActive(data?.isActive);
    setDays(data?.days);
  }

  useEffect(() => {
    getCoupons();
  }, [reload]);

  useEffect(() => {
    getCatagories();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Coupons</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    New Coupon
                  </ButtonCompo>
                }
                title="Add New Coupon"
                actionButton={
                  <ButtonCompo onClick={addCoupon}>Save</ButtonCompo>
                }
              >
                <div className="space-y-3">
                  <InputFieldsWithIcon
                    label="Coupon Name"
                    onChange={(event) => setCouponName(event?.target?.value)}
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="Days"
                    placeholder="Coupon will valid for how many days"
                    onChange={(event) => setDays(Number(event?.target?.value))}
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="Discount"
                    onChange={(event) =>
                      setDiscount(Number(event?.target?.value))
                    }
                    isImp={true}
                  />
                  <SelectField
                    isImp={true}
                    label={"Discount Type"}
                    setValue={(event) => setDiscountType(event?.target?.value)}
                    valueArray={discountTypeArray}
                  />
                  <SelectField
                    isImp={true}
                    label={"Privacy"}
                    setValue={(event) => setIsPublic(event?.target?.value)}
                    valueArray={isPublicArray}
                  />
                  <InputFieldsWithIcon
                    label="Minimum Amount"
                    placeholder="Coupon only applied above the amount"
                    onChange={(event) =>
                      setMinimumAmount(Number(event?.target?.value))
                    }
                    isImp={true}
                  />
                </div>
              </DialogBox>
            </div>
            {couponData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Coupon
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Minimum Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Days valid for
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Discount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Expire At
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Status
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Privacy
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Edit
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {couponData?.map((e, i) => (
                        <TableRow>
                          <TableCell className="!text-center !font-bold">
                            {Number(i) + 1}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.coupon}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.minimumAmount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.days}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.discountType === "Percentage"
                              ? `${e?.discount}%`
                              : `Rs.${e?.discount}`}
                          </TableCell>
                          <TableCell className="!text-center">
                            {new Date(e?.expireAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.isActive === true ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.isPublic === true ? "Public" : "Private"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={
                                <ButtonCompo onClick={() => setValue(e)}>
                                  Edit
                                </ButtonCompo>
                              }
                              title="Edit Coupon"
                              actionButton={
                                <ButtonCompo
                                  onClick={() => updateCoupon(e?._id)}
                                >
                                  Save
                                </ButtonCompo>
                              }
                            >
                              <div className="space-y-3">
                                <InputFieldsWithIcon
                                  label="Coupon Name"
                                  onChange={(event) =>
                                    setCouponName(event?.target?.value)
                                  }
                                  isImp={true}
                                  value={couponName}
                                />
                                <InputFieldsWithIcon
                                  label="Days"
                                  placeholder="Coupon will valid for how many days"
                                  onChange={(event) =>
                                    setDays(Number(event?.target?.value))
                                  }
                                  isImp={true}
                                  value={days}
                                />
                                <InputFieldsWithIcon
                                  label="Discount"
                                  onChange={(event) =>
                                    setDiscount(Number(event?.target?.value))
                                  }
                                  isImp={true}
                                  value={discount}
                                />
                                <SelectField
                                  isImp={true}
                                  label={"Discount Type"}
                                  setValue={(event) =>
                                    setDiscountType(event?.target?.value)
                                  }
                                  value={discountType}
                                  valueArray={discountTypeArray}
                                />
                                <SelectField
                                  isImp={true}
                                  label={"Privacy"}
                                  setValue={(event) =>
                                    setIsPublic(event?.target?.value)
                                  }
                                  value={isPublic}
                                  valueArray={isPublicArray}
                                />
                                <SelectField
                                  isImp={true}
                                  label={"Status"}
                                  setValue={(event) =>
                                    setIsActive(event?.target?.value)
                                  }
                                  value={isActive}
                                  valueArray={isActiveArray}
                                />
                                <InputFieldsWithIcon
                                  label="Minimum Amount"
                                  placeholder="Coupon only applied above the amount"
                                  onChange={(event) =>
                                    setMinimumAmount(
                                      Number(event?.target?.value)
                                    )
                                  }
                                  isImp={true}
                                  value={minimumAmount}
                                />
                              </div>
                            </DialogBox>
                          </TableCell>
                          <TableCell className="!text-center">
                            <ButtonCompo
                              variant={"destructive"}
                              onClick={() => deleteCoupon(e?._id)}
                            >
                              Delete
                            </ButtonCompo>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STCoupons;
