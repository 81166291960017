import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import ButtonCompo from "../../component/ButtonCompo";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { axiosInstance } from "../../constant";

function SACompanySetting() {
  // navigate to home if superadmin is not logged
  const isSuperadminAuthenticated = useSelector(
    (state) => state.superadminSlice?.isSuperadminAuthenticated
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (
      isSuperadminAuthenticated !== undefined &&
      isSuperadminAuthenticated === false
    ) {
      navigate("/");
    }
  }, [isSuperadminAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // form hooks
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [website, setWebsite] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [address, setAddress] = useState("");

  // get company data
  async function getCompany() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/company/get/data`);
      const rawData = res.data?.data;
      console.log(rawData)
      setCompanyName(rawData?.companyName)
      setEmail(rawData?.email)
      setWebsite(rawData?.website)
      setAddress(rawData?.address)
      setGstNo(rawData?.gstNo)
      setContact(rawData?.contact)
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // update data
  async function updateData() {
    const data = {
        companyName: companyName,
        email: email,
        contact: contact,
        website: website,
        gstNo: gstNo,
        address: address
    }
    console.log(data)
    setLoading(true)
    try {
      const res = await axiosInstance.put("/api/v1/company/update", data);
      setOpen(true);
      setSuccess(true);
      setMessage("Company added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  console.log(gstNo)

  useEffect(() => {
    getCompany()
  },[])

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[30rem] flex flex-col justify-center items-center">
          <section className="text-center">
            <h1 className="text-[30px] font-semibold text-orange-800">
              Company Settings
            </h1>
            <p className="text-orange-800/70 mt-1 font-medium">
              Update your company related data
            </p>
          </section>
          <div className="mt-[3rem] w-full space-y-3">
            <InputFieldsWithIcon
              label="Company Name"
              value={companyName}
              onChange={(event) => setCompanyName(event?.target?.value)}
            />
            <InputFieldsWithIcon
              label="Company Email"
              value={email}
              onChange={(event) => setEmail(event?.target?.value)}
            />
            <InputFieldsWithIcon
              label="Company Contact Number"
              value={contact}
              onChange={(event) => setContact(event?.target?.value)}
            />
            <InputFieldsWithIcon
              label="Company Website"
              value={website}
              onChange={(event) => setWebsite(event?.target?.value)}
            />
            <InputFieldsWithIcon
              label="Company GST No."
              value={gstNo}
              onChange={(event) => setGstNo(event?.target?.value)}
            />
            <InputFieldsWithIcon
              label="Company Address"
              value={address}
              onChange={(event) => setAddress(event?.target?.value)}
            />
          </div>
          <section className="mt-3 flex justify-end w-full">
            <ButtonCompo onClick={updateData}>Save Changes</ButtonCompo>
          </section>
        </div>
      </div>
    </>
  );
}

export default SACompanySetting;
