import React from "react";
import Header from "../component/Header";
import { Outlet } from "react-router-dom";
import Footer from "../component/Footer";

function Layout() {
  return (
    <div className="w-full">
      <Header />
      <div className="">
        <div className="w-[30rem] h-[30rem] top-[-19rem] z-[-100] right-[23rem] bg-[#FFD0A1]/60 fixed rounded-full blur-[10rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[-19rem] z-[-100] right-[23rem] bg-[#FFDA55]/60 fixed rounded-full blur-[10rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[2rem] z-[-100] right-[-13rem]  bg-[#FFD0A1]/60 fixed rounded-full blur-[20rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[2rem] z-[-100] right-[-13rem]  bg-[#FFDA55]/60 fixed rounded-full blur-[20rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[-10rem] z-[-100] left-[-10rem] bg-[#FFD0A1]/60 fixed rounded-full blur-[10rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[-10rem] z-[-100] left-[-10rem] bg-[#FFDA55]/60 fixed rounded-full blur-[10rem]"></div>
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
