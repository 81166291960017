import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth.slice"
import reloadSlice from "./reload.slice"
import superadminSlice from "./superadmin.slice"
import staffSlice from "./staff.slice"
import cartNumber from "./cartNumber"

export const store = configureStore({
    reducer: {
        auth: auth,
        reload: reloadSlice,
        superadminSlice: superadminSlice,
        staffSlice: staffSlice,
        cartNumber: cartNumber
    }
})