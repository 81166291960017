import React from "react";
import notFound from "../assets/notFound.svg"

function NotFound({width="20rem"}) {
  return (
    <div className="w-full flex justify-center items-center flex-col gap-3">
      <img src={notFound} className={`w-[${width}]`} alt="" />
      <p className="text-center text-slate-500 text-[20px]">No data found</p>
    </div>
  );
}

export default NotFound;
