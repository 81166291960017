import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";

function AccrodianBox({
  title,
  titleClass,
  headClass,
  iconClass,
  id,
  children,
  ...props
}) {
  return (
    <Accordion {...props}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className={iconClass} />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
        className={headClass}
      >
        <Typography className={titleClass}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccrodianBox;
