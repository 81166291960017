import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

function SelectField({ value, setValue, label, isImp, valueArray }) {
  return (
    <FormControl className="w-full space-y-3">
      {label && (
        <label>
          {label}{" "}
          {isImp === true ? <span className="text-red-500">*</span> : ""}
        </label>
      )}
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        label={label}
        onChange={setValue}
        className="!bg-white !border !border-[#cbd5e1]"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none", // Ensure no border on focus
          },
          backgroundColor: "#fff", // Ensure background color remains
        }}
      >
        {valueArray?.map((e, i) => (
          <MenuItem value={e?.value} key={i}>
            {e?.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;
