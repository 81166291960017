import React from "react";
import logo from "../../assets/companyLogo.png";
import { FaMoneyBill1 } from "react-icons/fa6";
import DrawerCompo from "../DrawerCompo";
import { MdDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsFillInboxesFill } from "react-icons/bs";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";

function STHeader() {
  const dataArray = [
    {
      heading: "Home",
      content: [
        {
          title: <p className="text-orange-500/90 font-bold">Dashboard</p>,
          icon: <MdDashboard size={"22px"} className="text-orange-400" />,
          link: "/admin/dashboard",
        },
      ],
    },
    {
      heading: "products",
      content: [
        {
          title: "Products",
          icon: <AiFillProduct size={"22px"} className="text-slate-800/60" />,
          link: "/admin/products",
        },
        {
          title: "Add Product",
          icon: <AiFillProduct size={"22px"} className="text-slate-800/60" />,
          link: "/admin/product/add",
        },
        {
          title: "Catagories",
          icon: <BsFillInboxesFill size={"22px"} className="text-slate-800/60" />,
          link: "/admin/catagories",
        },
      ],
    },
    {
      heading: "orders",
      content: [
        {
          title: "New Orders",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/orders/new",
        },
        {
          title: "Order History",
          icon: <FaGift size={"22px"} className="text-slate-800/60" />,
          link: "/admin/orders",
        }
      ]
    },
    {
      heading: "miscellaneous",
      content: [
        {
          title: "Coupons",
          icon: <RiCoupon3Fill size={"22px"} className="text-slate-800/60" />,
          link: "/admin/coupons",
        },
        {
          title: "Charges",
          icon: <FaMoneyBill1 size={"22px"} className="text-slate-800/60" />,
          link: "/admin/charges",
        },
        {
          title: "Banners",
          icon: <FaImage size={"22px"} className="text-slate-800/60" />,
          link: "/admin/banners",
        },
        {
          title: "Users",
          icon: <FaUsers size={"22px"} className="text-slate-800/60" />,
          link: "/admin/users",
        },
        {
          title: "Inquiry",
          icon: <MdContactMail size={"22px"} className="text-slate-800/60" />,
          link: "/admin/contact",
        },
      ],
    }
  ];

  return (
    <header className="w-full px-5 py-3 fixed top-0 flex justify-between items-center">
      <DrawerCompo dataArray={dataArray} heading={"Admin"} />
      <img src={logo} className="w-[3rem]" alt="" />
    </header>
  );
}

export default STHeader;
