import React, { useEffect } from "react";
import Heading from "../component/Heading";

function ShippingAndDelivery() {

    useEffect(() =>{
        window.scrollTo(0,0)
    },[])

  return (
    <div className="w-full flex justify-center items-center mt-[7rem]">
      <div className="w-[90vw]">
        <Heading>Shipping And Delivery</Heading>
        <div className="w-full mt-[3rem] space-y-5">
          <p>
            <span className="underline">
              <strong>&nbsp;Shipping</strong>
            </span>
          </p>
          <p>
            At NBS, we offer free shipping for all orders with an order value of
            Rs. 450 and above.
            <br />
            We only make use of reliable courier companies and/or Speed Post.
            NBS is not liable for any delays in delivery by the Courier Company/
            Speed Post or on account of any factors that cannot be controlled by
            NBS. We shall strive to ensure timely delivery of your order within
            the estimated time as mentioned in the order confirmation.
            <br />
            In the unlikely event that delivery is not possible for any reasons
            beyond the control of NBS, including but not limited to poor courier
            services in delivery area, inaccessibility of delivery area, force
            major events as laid out hereinafter, we shall keep you informed of
            such inability to deliver and cancel your order, followed by
            complete refund.
          </p>
          <p>
            <span className="underline">
              <strong>Cancellations</strong>
            </span>
          </p>
          <p>
            We <u>will </u>be able to accept a cancellation request any time
            before we ship your order. In case your credit card/debit card/bank
            account was already charged for the order, we shall process the
            refund immediately following cancellation of order, subject to
            deductions for any banking transaction charges incurred by us
            pursuant to your order and its cancellation.
          </p>
          <p>
            <em>
              <strong className="underline">Returns Policy</strong>
            </em>
          </p>
          <p>
            We are proud that all our shipments go through 2 levels of packaging
            and 2 levels of inspection before dispatch. The likelihood of damage
            is very small. However, in case there is any damage, here’s how you
            can get a replacement:
            <br />
            NBS offers a replacement guarantee for all products ordered online.
            Products will be replaced only in case of a manufacturing defect or
            damage due to transportation.
            <br />
            If you are eligible for a replacement, you may notify us within 2
            days from the date of delivery.
            <br />
            Before confirming an exchange, we would require proof of defect in
            product in the form of a photo, while the product is still in its
            packaging.
            <br />
            In case the product you have returned is out of stock, we will
            provide you with a credit note equal to the value of the product you
            purchased. The credit note will be sent to you via email and can be
            used to purchase anything of that value from the online store within
            three months.
          </p>
          <p>
            <span>
              <strong className="underline">Currency Transactions</strong>
            </span>
          </p>
          <p>
            All transactions are processed in Indian Rupees (INR).
            <br />
            Processing Orders
            <br />
            Our business hours are 10 am to 6 pm Indian Standard Time (IST),
            Monday to Friday. If you need to contact us outside our business
            hours, please email us at{" "}
            <a href="support@nutribsc.com">
              <span className="underline">
                <strong>support@nutribsc.com</strong>
              </span>
            </a>{" "}
            and we will respond as soon as possible.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShippingAndDelivery;
