import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PopOverBox from "../../component/PopoverBox";
import PaginationBar from "../../component/PaginationBar";

function STUsers() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const alertRef = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");

  // get products
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/user/get/all/user/staff/${page}`
      );
      setProductData(res.data?.data?.data);
      setTotalPages(res.data?.data?.totalPages);
      console.log(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete product
  async function deleteProduct() {
    if (deleteConfirmation === false) {
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/delete/${deleteId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Product deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setDeleteConfirmation(false);
      setDeleteId("");
      setDeleteTitle("");
      setLoading(false);
    }
  }

  const handleAlertOpen = (productId, productTitle) => {
    if (alertRef.current) {
      setDeleteId(productId);
      setDeleteTitle(productTitle);
      alertRef.current.handleOpen();
    }
  };

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setSearch("");
    setReload((prev) => !prev);
  }

  useEffect(() => {
    getProducts();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          ref={alertRef}
          actionButton={
            <ButtonCompo
              onClick={() => {
                setDeleteConfirmation(true);
                deleteProduct();
              }}
            >
              <span className="text-red-600 font-semibold text-[16px]">
                Delete
              </span>
            </ButtonCompo>
          }
          closeActionButton={"cancel"}
        >
          <div>
            <p>
              You're going to delete{" "}
              <span className="font-semibold">{deleteTitle}</span>
            </p>
            <p className="mt-3">
              Do want to{" "}
              <span className="text-red-600 font-semibold">delete</span> it?
            </p>
          </div>
        </DialogBox>
        <div className="w-[90vw]">
          <Heading>Users</Heading>
          <div className="w-full mt-5">
            {productData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Name
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Email ID
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Gender
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Country
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Addresses
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {productData?.map((j, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {j?.fullName}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.email}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2">
                            {j?.phone}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.gender ? j?.gender : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            {j?.country ? j?.country : "NIL"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={<ButtonCompo>View</ButtonCompo>}
                              maxWidth="sm"
                            >
                              <div className="w-full">
                                {j?.address?.map((e, i) => (
                                  <section className="w-full bg-white px-5 py-5 rounded-[5px] shadow-md space-y-2">
                                    <div className="flex w-full justify-between">
                                      <section className="flex gap-[3rem]">
                                        <p className="font-semibold text-[16px]">
                                          {e?.fullName}
                                        </p>
                                        <p className="font-semibold text-[16px]">
                                          Phone : <span>{e?.phone}</span>
                                        </p>
                                      </section>
                                    </div>
                                    <section>
                                      <p className="font-semibold text-[15px]">
                                        Pincode : <span>{e?.pincode}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        City : <span>{e?.city}</span>
                                      </p>
                                      <p className="font-semibold text-[15px]">
                                        State : <span>{e?.state}</span>
                                      </p>
                                    </section>
                                    <div className="w-full text-slate-500 text-[15px]">
                                      {`${e?.streetName1}, ${e?.streetName2}`}
                                    </div>
                                  </section>
                                ))}
                              </div>
                            </DialogBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div>
                    <PaginationBar
                      totalTablePage={totalPages}
                      setTablePage={setPage}
                      tablePage={page}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STUsers;
