import React, { useEffect, useState } from "react";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import ButtonCompo from "../../component/ButtonCompo";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance, passwordRegex, stringRegex } from "../../constant";
import PasswordField from "../../component/PasswordField";
import Loading from "../../component/Loading";
import AlertBox from "../../component/AlertBox";

function SAAddStaff() {
  // navigate to home if superadmin is not logged
  const isSuperadminAuthenticated = useSelector(
    (state) => state.superadminSlice?.isSuperadminAuthenticated
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (
      isSuperadminAuthenticated !== undefined &&
      isSuperadminAuthenticated === false
    ) {
      navigate("/");
    }
  }, [isSuperadminAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // add staff function
  async function addStaff(data) {
    console.log(data);
    setLoading(true)
    try {
      const res = await axiosInstance.post("/api/v1/staff/add", data);
      setOpen(true);
      setSuccess(true);
      setMessage("Staff added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[30rem] flex flex-col justify-center items-center">
          <section className="text-center">
            <h1 className="text-[30px] font-semibold text-orange-800">
              Add Staff
            </h1>
            <p className="text-orange-800/70 mt-1 font-medium">
              Add a new staff to manage your business on behalf of you
            </p>
          </section>
          <form className="w-full" onSubmit={handleSubmit(addStaff)}>
            <div className="w-full space-y-3 mt-5">
              <InputFieldsWithIcon
                label="Name"
                {...register("name", {
                  pattern: {
                    value: stringRegex,
                    message: "Don't use any number or special character",
                  },
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors?.name}
              />
              <InputFieldsWithIcon
                label="Email"
                type="email"
                {...register("email")}
              />
              <InputFieldsWithIcon
                label="Username"
                {...register("username", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
                error={errors?.username}
              />
              <PasswordField
                {...register("password", {
                  pattern: {
                    value: passwordRegex,
                    message:
                      "Password should be minimum 8 characters. At least 1 number, 1 capital letter, 1 small letter, 1 special character is required.",
                  },
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                })}
                error={errors?.password}
              />
            </div>
            <div className="w-full flex justify-end mt-5">
              <ButtonCompo type="submit" variant={"primary"}>
                Save
              </ButtonCompo>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SAAddStaff;
