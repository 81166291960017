import { LinearProgress } from "@mui/material";
import React from "react";

function Loading() {
  return (
    <LinearProgress
      className="!fixed !top-0 !left-0 !right-0 !h-[10px] !bg-[#FFDB57] !z-[100000000]"
      sx={{
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#FF8000",
        },
      }}
    />
  );
}

export default Loading;
