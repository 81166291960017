import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isAuthenticated: undefined,
    userData: null
}

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true,
            state.userData = action.payload?.userData
        },
        logout: (state) => {
            state.isAuthenticated = false,
            state.userData = null
        }
    }
})

export const {login, logout} = auth.actions
export default auth.reducer