import { Button } from "@mui/material";
import React from "react";

function ButtonCompo({ variant, color, children, startIcon, ...props }) {
  if (variant === "primary") {
    return (
      <Button
        variant={variant}
        color={color}
        className="!text-white !bg-[#FF8000] hover:!bg-orange-600 !font-semibold !px-7 !py-2"
        {...props}
        startIcon={startIcon}
      >
        {children}
      </Button>
    );
  } else if (variant === "outline") {
    return (
      <Button
        variant={variant}
        color={color}
        className="!text-[#FF6000] hover:!bg-orange-100 !font-semibold !px-7 !py-2"
        {...props}
        startIcon={startIcon}
        sx={{
          border: "1px solid #FF8000",
        }}
      >
        {children}
      </Button>
    );
  } else if (variant === "destructive") {
    return (
      <Button
        variant={variant}
        color={color}
        className="!text-white !bg-red-500 hover:!bg-red-600 !font-semibold !px-7 !py-2"
        {...props}
        startIcon={startIcon}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      variant={variant}
      color={color}
      className="hover:!bg-orange-100/50 !text-[#FF8000] !font-semibold !py-2"
      {...props}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
}

export default ButtonCompo;
