import React, { useState } from "react";
import { Popover } from "@mui/material";

function PopOverBox({ trigger, dataArray, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event?.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div onClick={handleClick} className="cursor-pointer">
        {trigger}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        {...props}
        className="!z-[100000]"
      >
        <div className="w-full flex flex-col z-[10000]">
          {dataArray?.map((e, i) => (
            <section key={i} onClick={handleClose} className="py-1">
              {e}
            </section>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default PopOverBox;
