import { Autocomplete, TextField } from "@mui/material";
import React from "react";

function AutocompleteCompo({ label, isImp, placeholder, dataArray, error, ...props }) {
  return (
    <section className="space-y-3">
      {label && (
        <label>
          {label}{" "}
          {isImp === true ? <span className="text-red-500">*</span> : ""}
        </label>
      )}
      <Autocomplete
        disablePortal
        options={dataArray}
        sx={{ width: 300 }}
        className="!w-full"
        {...props}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder}
            {...params}
            color={error !== undefined ? "error" : "primary"}
            className="!w-full !bg-white !rounded-[10px]"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#fff", // Background color for the input
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FF8000", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#FF8000", // Border color on focus
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#cbd5e1", // Default border color
              },
            }}
          />
        )}
      />
      {error && (
        <p className="mt-2 text-red-500 text-[16px]">*{error?.message}</p>
      )}
    </section>
  );
}

export default AutocompleteCompo;
