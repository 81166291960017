import { InputAdornment, TextField } from "@mui/material";
import React from "react";

const InputFieldsWithIcon = React.forwardRef(function InputFieldsWithIcon(
  { label, placeholder, icon, variant, iconPosition, isImp, error, ...props },
  ref
) {
  return (
    <section className="space-y-3">
      {label && (
        <label>
          {label} {isImp === true ? <span className="text-red-500">*</span> : ""}
        </label>
      )}
      <TextField
        placeholder={placeholder}
        variant={variant}
        {...props}
        ref={ref}
        color={error !== undefined ? "error" : "primary"}
        className="!w-full !bg-white !rounded-[10px]"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position={iconPosition}>{icon}</InputAdornment>
            ),
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff", // Background color for the input
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF8000", // Border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF8000", // Border color on focus
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#cbd5e1", // Default border color
          },
        }}
      />
      {error && (
        <p className="mt-2 text-red-500 text-[16px]">*{error?.message}</p>
      )}
    </section>
  );
});

export default InputFieldsWithIcon;
