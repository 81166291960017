import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
  import React, { forwardRef, useImperativeHandle, useState } from "react";
  import { BsXLg } from "react-icons/bs";
import ButtonCompo from "./ButtonCompo";
  
  const DialogBox = forwardRef(function DialogBox(
    {
      trigger,
      title,
      subTitle,
      actionButton,
      children,
      maxWidth = "xs",
      closeActionButton,
      ...props
    },
    ref
  ) {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    useImperativeHandle(ref, () => ({
      handleClose,
      handleOpen,
    }));
  
    return (
      <>
        <div onClick={handleOpen}>{trigger}</div>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={maxWidth}
          fullWidth={true}
          {...props}
        >
          <section className="flex justify-between items-start">
            {title && (
              <>
                <section>
                  <DialogTitle>{title}</DialogTitle>
                </section>
                <div className="px-[16px] py-[24px]">
                  <BsXLg
                    size={"17px"}
                    onClick={handleClose}
                    className="cursor-pointer"
                  />
                </div>
              </>
            )}
          </section>
          <DialogContent>
            {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
            {children}
          </DialogContent>
          {actionButton !== undefined || closeActionButton !== undefined ? (
            <DialogActions>
              {closeActionButton && (
                <ButtonCompo onClick={handleClose}>{closeActionButton}</ButtonCompo>
              )}
              {actionButton && <div onClick={handleClose}>{actionButton}</div>}
            </DialogActions>
          ) : (
            ""
          )}
        </Dialog>
      </>
    );
  });
  
  export default DialogBox;