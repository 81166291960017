import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";

const PasswordField = React.forwardRef(function PasswordField({ variant, error, ...props }, ref) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl sx={{ width: "100%" }} variant={"outlined"}>
      <label className="mb-3">Password</label>
      <OutlinedInput
        id="outlined-adornment-password"
        color={error !== undefined ? "error" : "primary"}
        type={showPassword ? "text" : "password"}
        {...props}
        ref={ref}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
              className="!text-slate-400"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#cbd5e1", // Default border color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF8000", // Border color when focused
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF8000", // Border color on hover
          },
          backgroundColor: "#fff",
        }}
      />
      {error && <p className="mt-2 text-red-500 text-[16px]">*{error?.message}</p>}
    </FormControl>
  );
})

export default PasswordField;
