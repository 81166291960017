import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { IoMdRefresh } from "react-icons/io";
import { MdEditSquare } from "react-icons/md";
import { MdDelete } from "react-icons/md";

function STVideoReview() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const { productId } = useParams();

  // video data
  const [fullName, setFullName] = useState("");
  const [url, setUrl] = useState("");
  const [desc, setDesc] = useState("");

  // get videos
  async function getVideos() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/review/video/get/product/${productId}`
      );
      console.log(res.data?.data);
      setVideoData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add video
  async function addVideo() {
    if (fullName === "" || url === "" || desc === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields is required");
      return null;
    }
    const data = {
      fullname: fullName,
      url: url,
      about: desc,
      productId: productId,
    };
    try {
      setLoading(true);
      const res = axiosInstance.post("/api/v1/product/review/video/add", data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Video added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // update video
  async function updateVideo(videoId) {
    const data = {
      fullName: fullName,
      url: url,
      about: desc,
      productId: productId,
      videoId: videoId
    };
    console.log(data)
    try {
      setLoading(true);
      const res = axiosInstance.put("/api/v1/product/review/video/edit", data);
      setOpen(true);
      setSuccess(true);
      setMessage("Video updated successfully");
      setReload((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete video
  async function deleteVideo(videoId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/review/video/delete/${videoId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Charge deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // set value
  function setValue(data) {
    setFullName(data?.fullName)
    setUrl(data?.url)
    setDesc(data?.about)
  }

  useEffect(() => {
    getVideos();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Videos</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    Add Video
                  </ButtonCompo>
                }
                title="Add New Video"
                actionButton={
                  <ButtonCompo onClick={addVideo}>Save</ButtonCompo>
                }
              >
                <div className="space-y-3">
                  <p className="text-slate-500">Only enter the code of video/reel (e.g, <span className="font-medium">Jw8sXzaZUDQ</span>)</p>
                  <InputFieldsWithIcon
                    label="YouTube URL"
                    onChange={(event) => setUrl(event?.target?.value)}
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="Title"
                    onChange={(event) => setFullName(event?.target?.value)}
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="About"
                    onChange={(event) => setDesc(event?.target?.value)}
                    isImp={true}
                  />
                </div>
              </DialogBox>
            </div>
            {videoData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5 flex gap-3">
                  {videoData?.map((video, i) => (
                    <section className="w-fit relative">
                      <iframe
                        width="250"
                        height="250"
                        src={`https://www.youtube.com/embed/${video?.url}?autoplay=1&loop=1&playlist=${video?.url}&mute=1`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                      <DialogBox
                        trigger={
                          <section className="p-2 bg-[#FF8000] rounded-full w-fit absolute top-2 right-2 cursor-pointer" onClick={() => setValue(video)}>
                            <MdEditSquare className="text-[20px] text-white" />
                          </section>
                        }
                        actionButton={<ButtonCompo onClick={() => updateVideo(video?._id)}>Save</ButtonCompo>}
                        title="Edit"
                      >
                        <div className="space-y-3">
                          <InputFieldsWithIcon
                            label="YouTube URL"
                            onChange={(event) => setUrl(event?.target?.value)}
                            isImp={true}
                            value={url}
                          />
                          <InputFieldsWithIcon
                            label="Title"
                            onChange={(event) =>
                              setFullName(event?.target?.value)
                            }
                            isImp={true}
                            value={fullName}
                          />
                          <InputFieldsWithIcon
                            label="About"
                            onChange={(event) => setDesc(event?.target?.value)}
                            isImp={true}
                            value={desc}
                          />
                        </div>
                      </DialogBox>
                      <section className="p-2 bg-red-600 rounded-full w-fit absolute top-14 right-2 cursor-pointer" onClick={() => deleteVideo(video?._id)}>
                        <MdDelete className="text-[20px] text-white" />
                      </section>
                    </section>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STVideoReview;
