import React from 'react'
import { Outlet } from 'react-router-dom'
import STHeader from '../../component/Staff/STHeader'

function STLayout() {
  return (
    <div className='w-full'>
        <STHeader />
        <div className='mt-[5rem]'>
            <Outlet />
        </div>
    </div>
  )
}

export default STLayout
