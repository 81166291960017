import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

function STContact() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);
  const alertRef = useRef(null)

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [contactData, setContactData] = useState([]);

  // get contacts
  async function getContacts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/contact/get/all`);
      console.log(res.data?.data);
      setContactData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getContacts();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Contact Messages</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
            </div>
            {contactData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Name
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Email ID
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Phone Number
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Message
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {contactData?.map((e, i) => (
                        <TableRow>
                          <TableCell className="!text-center !font-bold">
                            {Number(i) + 1}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.name}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.email}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.phone}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox trigger={<ButtonCompo>View Message</ButtonCompo>} title="Message" closeActionButton={"Close"}>{e?.msg}</DialogBox>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STContact;