import React, { useEffect } from "react";
import Heading from "../component/Heading";

function RefundAndReturns() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full flex justify-center items-center mt-[7rem]">
      <div className="w-[90vw]">
        <Heading>Refund And Returns</Heading>
        <div className="w-full mt-[3rem] space-y-5">
          <p>
            <span className="underline">
              <strong>Returns</strong>
            </span>
            :
          </p>
          <p>
            <br />
            No return requests will be accepted on items that have been
            delivered without any damages or defects.
            <br />
            If the customer wishes to return a delivered product, the onus of
            returning the product in mint form to NBS for a return or
            replacement will be borne by the customer.
          </p>
          <p>
            <br />
            <span className="underline">
              <strong>Replacements:</strong>
            </span>
            <br />
            If the product has a genuine quality/manufacturing defect, a full
            refund or replacement will be offered for your order against the
            photo and video of the package and the contents.
            <br />
            Although we ensure the highest quality of packing each order, there
            might be very rare circumstances of damage when shipping. In this
            case, contact nbsonline11@gmail.comand our customer support team
            will replace your order.
            <br />
            Conditions eligible for replacements are:
            <br />
            Water damage to the packaging
            <br />
            Tampered or opened seal of the jar/refill
            <br />
            Broken jar or torn refill pouch
          </p>
          <p>
            <br />
            <span className="underline">
              <strong>Cancellations:</strong>
            </span>
          </p>
          <p>
            <br />
            Cancellations made before dispatch, will receive a 100% refund. Our
            customer care team will look into the issue and revert within 24-48
            hours.
            <br />
            For cancellations post dispatch, our delivery partner might still
            attempt to deliver your order. In this case, kindly do not accept
            the order. The order will then be returned back to its source. Once
            our delivery partner updates the shipment status as “RTO (Return to
            origin) initiated”, we will initiate the refund within 5 to 7
            business days after a 10% deduction as transactional fee.
          </p>
          <p>
            <br />
            General conditions for returns, replacements, and cancellations:
            <br />
            Verify the conditions of the products after receiving the package
            and report any concerns immediately to Support@nutribsc.com
          </p>
          <p>
            <br />
            Products returned without all original packaging, opened mixes, and
            mixes hardened due to improper handling of product are not eligible
            for refunds, replacements. To avoid hardening of mixes, please read
            how to take care of your NBS jars.
            <br />
            Any and all complaints of the products, shipping, and packaging
            needs to be made within 24 hours of receiving the product to
            nbsonline11@gmail.comwith the pictures and video of the package
            received.
          </p>
          <p>
            <br />
            An unboxing video of the package received with the order number and
            total weight in its top right corner will the NBS team better assess
            the situation and resolve it for you faster.
          </p>
          <p>
            <br />
            <strong>
              WHAT DO I DO IF MY PACKAGE AND/OR PRODUCT IS DELIVERED WITH
              DAMAGE?
            </strong>
            <br />
            We always ensure the highest quality of packing and shipping of
            orders. Even in such cases, there might be one or two circumstances
            where the product might get damaged. We apologize for the
            inconvenience caused and will replace your product if it meets these
            conditions:
            <br />
            1. Water damage to the packaging
            <br />
            2. Tampered or opened seal of the jar/refill.
            <br />
            3. Broken jar or torn refill pouch.
          </p>
          <p>
            <br />
            <strong>Here’s what you should do immediately:</strong>
            <br />
            1. Please send an email to nbsonline11@gmail.comwithin 24 hours of
            receiving the product.
            <br />
            2. Take a detailed video of unboxing the package to show how it was
            delivered.
            <br />
            3. Please also take clear pictures of the same.
            <br />
            4. We will respond and resolve the issue for you 🙂
          </p>
          <p>
            <br />
            <strong>WHAT DO I DO IF MY PRODUCT IS MISSING/WRONG?</strong>
            <br />
            All our orders are tested for quality control before packing them.
            However, if you happen to receive your order with the wrong or
            missing items, please ensure that they meet the following criteria
            <br />
            1. One or two products missing from what was ordered
            <br />
            2. Entire order is wrong
            <br />
            3. Some products in the order are correct but some are wrong
            <br />
            Here’s what you should do immediately:
            <br />
            1. Please share the unboxing video and pictures via email to
            Support@nutribsc.com
            <br />
            within 24 hours of receiving the package.
            <br />
            2. Ensure the order number and the total weight written on the top
            right corner of the box is visible.
            <br />
            3. We will respond and resolve the issue for you 🙂
            <br />* Each of our boxes have their total weight written in pen on
            the top right corner of them to measure the correct quantity and
            size of the products ordered. This is essential in order to ship out
            our products. So it is very unlikely that a few products might be
            missing, but in case this happens, we apologize for the experience
            and would request you to kindly follow the above steps for us to
            serve you better! *
          </p>
          <p>
            <br />
            <strong>
              WHAT DO I DO IF I HAVE AN ISSUE WITH THE PRODUCT QUALITY?
            </strong>
            <br />
            All our product batches are tested internally once and also undergo
            a third party testing from a nationally certified organization. In
            case you feel that the quality of the product is not in sync with
            the brand’s standards, get in touch with nbsonline11@gmail.comand we
            will test and verify with the batch sample again to give you an
            update.
          </p>
          <p>
            <br />
            <strong>WHAT DO I DO IF MY PRODUCT HARDENS?</strong>
            <br />
            All our products are made with 100% plant-based, adaptogenic
            superfoods, and herbs. Since they do not contain any sort of
            stabilizers and preservatives, they become vulnerable and absorb
            moisture from the air. This makes them harden. However, the mix does
            not harden overnight and it can only happen if it is neglected for
            more than two weeks. Please read here on how to store your products!
            <br />
            No returns will be accepted if the product has been hardened due to
            negligence and improper storage.
          </p>
          <p>
            <br />
            <strong>HOW CAN I CANCEL MY ORDER?</strong>
            <br />
            If you have to cancel an order, please do so within 24 hours of
            placing the order.
            <br />
            If the order that you want to cancel has not been shipped yet, you
            can write to our customer support team on nbsonline11@gmail.com
            <br />
            In such cases, the order will be canceled and the money will be
            refunded to you within 5- 7 business days after the cancellation
            request.
            <br />
            3. In case you have canceled an order, which has already been
            dispatched from our end, our delivery partner may still attempt
            delivery. Kindly do not accept the delivery of the order. Once our
            delivery partner updates the shipment status as “RTO (Return to
            origin) initiated”, we will initiate the refund within 5 to 7
            business days after a 10% deduction as the transaction fee.
          </p>
          <p>
            <strong>Return, Refund and Cancellation Policies</strong>
          </p>
          <p>
            <br />
            <strong>
              WHAT IS THE GENERAL RETURN/REFUND POLICY ON NBS PRODUCTS?
            </strong>
            <br />
            1. No returns will be offered on Items that have been delivered
            without any damages or defects.
            <br />
            2. If the product has a genuine quality/manufacturing defect, then
            we are open to extending full refund or re-fulfilling your order
            against the return of the product.
            <br />
            3. Cancellations made in advance of the shipping date, will receive
            a 100% refund.
            <br />
            4. Our customer care team will look into the issue and revert within
            24-48 hours.
            <br />
            5. The following products shall not be eligible for return or
            replacement, viz. any product that is returned without all original
            packaging/opened mixes.
            <br />
            6. All costs of return shipping will be borne by the purchaser
            (unless the item received is defective).
            <br />
            7. In case you wish to cancel an order that has already been
            dispatched from our facility, please note that the delivery
            personnel may still attempt delivery. In which case, kindly do not
            accept the order. The order will then be returned to its source.
            Once we receive the product back and verify its packaging/condition,
            we will refund your money after a deduction of 10% of the order
            value as transaction fee, within 7 business days.
          </p>
          <p>
            <br />
            <strong>
              WHAT DO I DO IF MY PACKAGE AND/OR PRODUCT IS DELIVERED WITH
              DAMAGE?
            </strong>
            <br />
            If your order was delivered with the following damages, we
            apologize.
            <br />
            1. Water damage to the packaging
            <br />
            2. Tampered or opened seal of the jar/refill.
            <br />
            3. Broken jar or torn refill pouch.
            <br />
            4. The package is opened.
            <br />
            5. The package is torn.
          </p>
          <p>
            <br />
            <strong>Here’s what you should do immediately:</strong>
            <br />
            1. Take a detailed video of unboxing the package, to show how it was
            delivered.
            <br />
            2. Please take clear pictures of the same.
            <br />
            3. Submit your claim with details of the damage, pictures and videos
            to support@nutribsc.com
          </p>
          <p>4. Wait to hear back from our people on the next steps.</p>
          <p>
            <br />
            <strong>WHEN CAN I CANCEL MY ORDER?</strong>
            <br />
            1. If you have to cancel an order, please do so within 24 hours of
            placing the order.
            <br />
            2. If the order that you want to cancel has not been shipped yet,
            you can write to our customer support team on support@nutribsc.com
            <br />
            In such cases, the order will be cancelled and the money will be
            refunded to you within 7-10 business days after the cancellation
            request.
          </p>
          <p>
            3. In case you have cancelled an order, which has already been
            handed over to the courier company on our end, they may still
            attempt delivery. Kindly do not accept the delivery of the order.
            Once we receive the product back and verify its packaging/condition,
            we will refund your money within 7-10 business days.
          </p>
          <p>
            <strong>
              I GOT A NOTIFICATION THAT MY ORDER WAS DELIVERED. BUT IT WASN’T.
              WHAT SHOULD I DO?
            </strong>
            <br />
            We’re sorry for the mix up! We’ve had instances where the tracking
            information updates too quickly, and a delivery notification is sent
            24-48 hours prior to the package actually arriving. Keep an eye out
            for the next day or so, and let us know if it doesn’t arrive within
            48 hours after the delivery notification is sent so that we can
            assist you further!
          </p>
        </div>
      </div>
    </div>
  );
}

export default RefundAndReturns;
