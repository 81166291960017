import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isSuperadminAuthenticated: undefined,
    superadminData: null
}

const superadminSlice = createSlice({
    name: "superadminSlice",
    initialState,
    reducers: {
        superadminLogin: (state, action) => {
            state.isSuperadminAuthenticated = true,
            state.superadminData = action.payload?.superadminData
        },
        superadminLogout: (state) => {
            state.isSuperadminAuthenticated = false,
            state.superadminData = null
        }
    }
})

export const {superadminLogin, superadminLogout} = superadminSlice.actions
export default superadminSlice.reducer