import React, { useEffect, useRef, useState } from "react";
import Heading from "../../component/Heading";
import Loading from "../../component/Loading";
import { axiosInstance, SERVER_URL } from "../../constant";
import AlertBox from "../../component/AlertBox";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdCloudUpload } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";

function STBanners() {
  const [banners, setBanners] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  // get banners
  async function getBanners() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/banners/all`);
      console.log(res.data?.data);
      setBanners(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add banner
  async function addBanner() {
    if (file === null) {
      setOpen(true);
      setSuccess(false);
      setMessage("Image is required");
      return null;
    }
    const formData = new FormData();
    formData.append("bannerImg", file);
    try {
      setLoading(true);
      const res = axiosInstance.post("/api/v1/banners/add", formData);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Banner added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // delete banner
  async function deleteBanner(bannerId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/banners/delete/${bannerId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Banner deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  useEffect(() => {
    getBanners();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Banners</Heading>
          <div className="w-full flex justify-end gap-3 items-center mt-5">
            <ButtonCompo
              variant={"outline"}
              startIcon={<IoMdRefresh />}
              onClick={() => setReload((prev) => !prev)}
            >
              Refresh
            </ButtonCompo>
            <DialogBox
              trigger={
                <ButtonCompo variant={"primary"}>Add Banner</ButtonCompo>
              }
              title="Upload Banner"
              actionButton={<ButtonCompo onClick={addBanner}>Save</ButtonCompo>}
            >
              <div className="w-full">
                <input
                  type="file"
                  className="hidden"
                  onChange={(event) => setFile(event?.target?.files[0])}
                  ref={fileRef}
                  accept=".png, .jpg"
                />
                <section
                  onClick={() => fileRef?.current?.click()}
                  className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] mt-3 py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                >
                  <p className="text-slate-700 font-medium">
                    {file === null ? "Upload A Banner" : "1 file selcted"}
                  </p>
                  <MdCloudUpload size={"25px"} className="text-[#FF8000]" />
                </section>
              </div>
            </DialogBox>
          </div>
          <div>
            <div className="w-full flex flex-col gap-3 justify-center items-center mt-[3rem]">
              {banners?.map((e, i) => (
                <section className="w-[60vw] h-auto relative">
                  <section
                    className="absolute bg-red-500 p-3 text-white rounded-full right-2 top-2 hover:bg-red-600 cursor-pointer"
                    onClick={() => deleteBanner(e?._id)}
                  >
                    <MdDelete size={"30px"} />
                  </section>
                  <img
                    src={`${SERVER_URL}/api/v1/file/get/${e?.image}`}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </section>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default STBanners;
