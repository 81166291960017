import React from 'react'
import SAHeader from '../../component/Superadmin/SAHeader'
import { Outlet } from 'react-router-dom'

function SALayout() {
  return (
    <div className='w-full'>
        <SAHeader />
        <div className='mt-[5rem]'>
            <Outlet />
        </div>
    </div>
  )
}

export default SALayout
