import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../component/PasswordField";
import ButtonCompo from "../../component/ButtonCompo";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { axiosInstance } from "../../constant";

function SADashboard() {
  // fetch superadmin data
  const superadminData = useSelector(
    (state) => state.superadminSlice?.superadminData
  );
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [enableChangePassword, setEnableChangePassword] = useState(false);
  const [password, setPassword] = useState("");

  // navigate to home if superadmin is not logged
  const isSuperadminAuthenticated = useSelector(
    (state) => state.superadminSlice?.isSuperadminAuthenticated
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (
      isSuperadminAuthenticated !== undefined &&
      isSuperadminAuthenticated === false
    ) {
      navigate("/");
    }
  }, [isSuperadminAuthenticated]);

  // change password
  async function changePassword() {
    if (password === "" || superadminData === undefined) {
      setOpen(true);
      setSuccess(false);
      setMessage("Password is required");
      return null;
    }
    const data = {
      username: superadminData?.username,
      password: password,
    };
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        "/api/v1/superadmin/4868f8ad-b032-4951-a309-ce4b26574a36/forgot-password",
        data
      );
      setEnableChangePassword(false);
      setOpen(true);
      setSuccess(true);
      setMessage("Password changed successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // change password if superadmin login for first time
  useEffect(() => {
    if (superadminData !== undefined && superadminData?.isJoined === false) {
      setEnableChangePassword(true);
    }
  }, [superadminData]);

  // change password screen
  if (enableChangePassword) {
    return (
      <>
        {loading && <Loading />}
        <div className="w-full flex justify-center items-center flex-col">
          {success !== undefined && (
            <AlertBox
              open={open}
              setOpen={setOpen}
              success={success}
              title={message}
            />
          )}
          <div className="w-[30rem] flex justify-center items-center flex-col">
            <section className="text-center mb-3">
              <h1 className="text-[30px] font-semibold text-orange-800">
                Change Password
              </h1>
              <p className="text-orange-800/60 font-medium">
                Please change your default password
              </p>
            </section>
            <PasswordField
              onChange={(event) => setPassword(event?.target?.value)}
            />
            <div className="w-full flex justify-end mt-5">
              <ButtonCompo variant={"primary"} onClick={changePassword}>
                Save
              </ButtonCompo>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full flex justify-center items-center">
      <h1 className="heading_text text-[35px] font-medium">Superadmin</h1>
    </div>
  );
}

export default SADashboard;
