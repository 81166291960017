import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdCloudUpload } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import AutocompleteCompo from "../../component/AutocompleteCompo";
import SelectField from "../../component/SelectField";

function STCharges() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chargesData, setChargesData] = useState([]);
  const [reload, setReload] = useState(false);
  const [catagoryData, setCatagoryData] = useState([]);

  // coupon data
  const [title, setTitle] = useState("");
  const [chargeAmount, setChargeAmount] = useState(0);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [chargeType, setChargeType] = useState("");
  const [isEnable, setIsEnable] = useState(true);

  // get charges
  async function getCharges() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/charges/admin/get/all`);
      console.log(res.data?.data);
      setChargesData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add coupon
  async function addCharge() {
    if (title === "" || chargeType === "" || chargeAmount === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields is required");
      return null;
    }
    const data = {
      chargeAmount: chargeAmount,
      title: title,
      chargeType: chargeType,
      maximumAmount: maximumAmount,
      minimumAmount: minimumAmount,
      isEnable: isEnable,
    };
    try {
      setLoading(true);
      const res = axiosInstance.post("/api/v1/charges/add", data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Charge added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // update coupon
  async function updateCharge(chargeId) {
    const data = {
      chargeAmount: chargeAmount,
      title: title,
      chargeType: chargeType,
      maximumAmount: maximumAmount,
      minimumAmount: minimumAmount,
      isEnable: isEnable,
      chargeId: chargeId,
    };
    try {
      setLoading(true);
      const res = axiosInstance.put("/api/v1/charges/update", data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Charge updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete coupon
  async function deleteCharge(chargeId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/charges/delete/${chargeId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Charge deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // charge type array
  const chargeTypeArray = [
    {
      title: "Percentage",
      value: "Percentage",
    },
    {
      title: "Rupees",
      value: "Rupees",
    },
  ];

  // is enable
  const isEnableArray = [
    {
      title: "Enable",
      value: true,
    },
    {
      title: "Disbale",
      value: false,
    },
  ];

  // set value
  function setValue(data) {
    setChargeAmount(data?.chargeAmount);
    setMinimumAmount(Number(data?.minimumAmount));
    setTitle(data?.title);
    setIsEnable(data?.isEnable);
    setChargeType(data?.chargeType);
    setMaximumAmount(data?.maximumAmount);
  }

  useEffect(() => {
    getCharges();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Charges</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    Add Charge
                  </ButtonCompo>
                }
                title="Add New Charge"
                actionButton={
                  <ButtonCompo onClick={addCharge}>Save</ButtonCompo>
                }
              >
                <div className="space-y-3">
                  <InputFieldsWithIcon
                    label="Charge Name"
                    onChange={(event) => setTitle(event?.target?.value)}
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="Charge Amount"
                    onChange={(event) =>
                      setChargeAmount(Number(event?.target?.value))
                    }
                    isImp={true}
                  />
                  <SelectField
                    isImp={true}
                    label={"Charge Type"}
                    setValue={(event) => setChargeType(event?.target?.value)}
                    valueArray={chargeTypeArray}
                  />
                  <InputFieldsWithIcon
                    label="Minimum Amount"
                    onChange={(event) =>
                      setMinimumAmount(Number(event?.target?.value))
                    }
                    isImp={true}
                  />
                  <InputFieldsWithIcon
                    label="Maximum Amount"
                    onChange={(event) =>
                      setMaximumAmount(Number(event?.target?.value))
                    }
                    isImp={true}
                  />
                  <SelectField
                    isImp={true}
                    label={"Status"}
                    setValue={(event) => setIsEnable(event?.target?.value)}
                    valueArray={isEnableArray}
                  />
                </div>
              </DialogBox>
            </div>
            {chargesData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Title
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Charge Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Charge Type
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Minimum Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Maximum Amount
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Status
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Edit
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {chargesData?.map((e, i) => (
                        <TableRow>
                          <TableCell className="!text-center !font-bold">
                            {Number(i) + 1}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.title}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.chargeAmount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.chargeType}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.minimumAmount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.maximumAmount}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.isEnable === true ? "Enabled" : "Disabled"}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={
                                <ButtonCompo onClick={() => setValue(e)}>
                                  Edit
                                </ButtonCompo>
                              }
                              title="Edit Coupon"
                              actionButton={
                                <ButtonCompo
                                  onClick={() => updateCharge(e?._id)}
                                >
                                  Save
                                </ButtonCompo>
                              }
                            >
                              <div className="space-y-3">
                                <InputFieldsWithIcon
                                  label="Charge Name"
                                  onChange={(event) =>
                                    setTitle(event?.target?.value)
                                  }
                                  isImp={true}
                                  value={title}
                                />
                                <InputFieldsWithIcon
                                  label="Charge Amount"
                                  onChange={(event) =>
                                    setChargeAmount(
                                      Number(event?.target?.value)
                                    )
                                  }
                                  isImp={true}
                                  value={chargeAmount}
                                />
                                <SelectField
                                  isImp={true}
                                  label={"Charge Type"}
                                  setValue={(event) =>
                                    setChargeType(event?.target?.value)
                                  }
                                  valueArray={chargeTypeArray}
                                  value={chargeType}
                                />
                                <InputFieldsWithIcon
                                  label="Minimum Amount"
                                  onChange={(event) =>
                                    setMinimumAmount(
                                      Number(event?.target?.value)
                                    )
                                  }
                                  isImp={true}
                                  value={minimumAmount}
                                />
                                <InputFieldsWithIcon
                                  label="Maximum Amount"
                                  onChange={(event) =>
                                    setMaximumAmount(
                                      Number(event?.target?.value)
                                    )
                                  }
                                  isImp={true}
                                  value={maximumAmount}
                                />
                                <SelectField
                                  isImp={true}
                                  label={"Status"}
                                  setValue={(event) =>
                                    setIsEnable(event?.target?.value)
                                  }
                                  valueArray={isEnableArray}
                                  value={isEnable}
                                />
                              </div>
                            </DialogBox>
                          </TableCell>
                          <TableCell className="!text-center">
                            <ButtonCompo
                              variant={"destructive"}
                              onClick={() => deleteCharge(e?._id)}
                            >
                              Delete
                            </ButtonCompo>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STCharges;
