import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reload: false
}

const reloadSlice = createSlice({
    name: "reload",
    initialState,
    reducers: {
        reloadToggle: (state, action) => {
            state.reload = !state.reload
        }
    }
})

export const {reloadToggle} = reloadSlice.actions
export default reloadSlice.reducer