import {
  Box,
  Button,
  Drawer,
  List
} from "@mui/material";
import React from "react";
import { IoReorderThreeSharp } from "react-icons/io5";
import logo from "../assets/companyLogo.png";
import { Link } from "react-router-dom";

function DrawerCompo({ dataArray, heading }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <IoReorderThreeSharp size={"30px"} className="text-orange-800" />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
        >
          <List>
            <div className="w-full flex justify-center items-center flex-col py-5">
              <img src={logo} className="w-[8rem]" alt="" />
              <div className="w-full mt-5">
                <h1 className="heading_text text-center mb-5 text-[25px] font-bold">{heading}</h1>
                {dataArray?.map((e, i) => (
                  <div className="mb-5">
                    <h2 className="px-3 uppercase font-bold text-[13px] text-slate-500/70">{e?.heading}</h2>
                    {e?.content?.map((s, i) => (
                      <Link to={s?.link} className="">
                        <section className="flex justify-start px-3 items-center gap-3 hover:bg-slate-100 py-3">
                            {s?.icon}
                            <p className="text-[16px] font-medium text-slate-600">{s?.title}</p>
                        </section>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default DrawerCompo;
