import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isStaffAuthenticated: undefined,
    staffData: null
}

const staffSlice = createSlice({
    name: "staffSlice",
    initialState,
    reducers: {
        staffLogin: (state, action) => {
            state.isStaffAuthenticated = true,
            state.staffData = action.payload?.staffData
        },
        staffLogout: (state) => {
            state.isStaffAuthenticated = false,
            state.staffData = null
        }
    }
})

export const {staffLogin, staffLogout} = staffSlice.actions
export default staffSlice.reducer