import { Alert } from "@mui/material";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function AlertBox({ title, setOpen, open, success }) {
  if (open) {
    setTimeout(() => setOpen(false), 3000);
  }

  if (open && success === true) {
    return (
      <Alert
        variant="filled"
        severity="success"
        className="!fixed !left-1/2 !top-5 !transform w-[60vw] md:w-fit !-translate-x-1/2 !items-center !z-[10000000000] !bg-green-600 !shadow-lg"
      >
        {title}
      </Alert>
    );
  }

  if (open && success === false) {
    return (
      <Alert
        variant="filled"
        severity="error"
        className="!fixed !left-1/2 !top-5 !transform w-[60vw] md:w-fit !-translate-x-1/2 !items-center !z-[10000000000] !bg-red-600 !shadow-lg"
      >
        {title}
      </Alert>
    );
  }
}

export default AlertBox;